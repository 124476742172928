@import 'responsive';

.partners {
	padding: 146px 0;
	@include media(mdx) {
		padding: 80px 0;
	}
	@include media(sm) {
		padding: 60px 0;
	}
	&__wrap {
		display: flex;
		justify-content: space-between;
		gap: 25px 20px;
		align-items: center;
		@include media(mdx) {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	&__item {
		img {
			display: block;	
			max-width: 100%;
		}
	}
}
