@import 'responsive';

.gallery {
	background-color: #010101;
	padding-bottom: 106px;
	@include media(mdx) {
		padding-bottom: 100px;
	}
	@include media(sm) {
		padding-bottom: 60px;
	}
	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 40px;
		@include media(mdx) {
			gap: 30px;
		}
		@include media(sm) {
			gap: 20px;
		}
		@include media(xxs) {
			gap: 15px;
		}
		@include media(small) {
			gap: 10px;
		}
	}
	&__item {
		img {
			display: block;
			max-width: 100%;
		}
	}
	&__btn {
		position: relative;
		display: block;
		cursor: pointer;
		max-width: 390px;
		width: 100%;
		font-size: 32px;
		line-height: 1.2;
		color: #3BEEB7;
		background-color: transparent;
		text-transform: uppercase;
		border-radius: 40px;
		padding: 20px 5px;
		margin: 0 auto;
		margin-top: 33px;
		border: 1px solid #3BEEB7;
		font-family: Rubik, sans-serif;
		transition: 0.25s color, 0.25s border-color, 0.25s background-color;
		@include media(sm) {
			font-size: 24px;
			padding: 15px 5px;
		}
		&:hover {
			background-color: #3beeb7;
			border-color: #3beeb7;
			color: #000;
		}
		&:after {
			position: absolute;
			cursor: default;
			top: 100%;
			left: 0;
			opacity: 0;
			visibility: hidden;
			content: 'The portfolio will be available soon!';
			color: #3BEEB7;
			background-color: transparent;
			font-size: 16px;
			width: 100%;
			height: 100%;
			padding: 15px 10px;
			text-transform: none;
		}
		&.active:after {
			opacity: 1;
			visibility: visible;
		}
	}
}