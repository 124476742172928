@import 'responsive';

.products {
	&-wrap {
		padding-top: 170px;
		padding-bottom: 280px;
		@include media(mdx) {
			padding-bottom: 100px;
			padding-bottom: 140px;
		}
		@include media(sm) {
			padding-top: 100px;
			padding-bottom: 100px;
		}
	}
	&__title {
		font-size: 32px;
		color: #000000;
		text-align: center;
		padding-bottom: 20px;
		font-weight: 900;
		@include media(sm) {
			font-size: 24px;
		}
	}
	&__text {
		max-width: 378px;
		margin: 0 auto;
		text-align: center;
		p {
			font-size: 20px;
			line-height: 1.5;
			@include media(sm) {
				font-size: 18px;
			}
		}
	}
	.swiper-slide {
		position: relative;
		height: auto;
		background-color: #F4F9FD;
		border-radius: 40px;
		padding: 0 10px;
		padding-top: 430px;
		padding-bottom: 74px;
		@include media(mdx) {
			padding-top: 380px;	
		}
		@include media(sm) {
			border-radius: 20px;
			padding-bottom: 54px;	
		}
		&:nth-child(5) {
			.products__image {
				top: -6px;
			}
		}
		&:nth-child(6) {
			.products__image {
				top: 94px;
			}
		}
		&:nth-child(7) {
			.products__image {
				top: -14px;
			}
		}
		&:nth-child(8) {
			.products__image {
				top: 24px;
			}
		}
	}
	&__btn {
		cursor: pointer;
		position: absolute;
		top: calc(100% - 45px);
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 90px;
		height: 90px;
		border: none;
		background: transparent;
		padding: 0;
		@include media(sm) {
			top: calc(100% - 35px);
			width: 70px;
			height: 70px;
		}
		svg {
			display: block;
			width: 100%;
			height: 100%;
			max-width: 100%;
		    fill: #3BEEB7;
		    transition: 0.25s fill;
		}
		&:hover {
		    svg {
		      fill: #35d4a4;
		    }
		}
	}
	&__image {
		max-width: 400px;
		width: 100%;
		position: absolute;
		top: -60px;
		left: 50%;
		transform: translateX(-50%);
		@include media(mdx) {
			max-width: 310px;
		}
		img {
			display: block;
			max-width: 100%;
			margin: 0 auto;
		}
	}
	&__block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include media(xxs) {
			display: block;
		}
	}
	&__holder {
		display: flex;
		gap: 18px;
		align-items: center;
		@include media(xxs) {
			margin-top: 30px;
		}
	}
}