.footer__holder {
  background-color: #010101;
  padding-top: 84px;
  padding-bottom: 60px;
}
@media screen and (max-width: 1023px) {
  .footer__holder {
    padding-top: 60px;
  }
}
.footer__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 1023px) {
  .footer__wrap {
    justify-content: center;
  }
}
.footer__item {
  width: 50%;
}
@media screen and (max-width: 1023px) {
  .footer__item {
    width: 100%;
  }
}
.footer__item:last-child {
  padding-top: 90px;
}
@media screen and (max-width: 1023px) {
  .footer__item:last-child {
    padding-top: 0;
  }
}
.footer__block {
  max-width: 580px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
}
@media screen and (max-width: 1023px) {
  .footer__block {
    margin-left: auto;
    margin-right: auto;
  }
}
.footer__title {
  font-size: 20px;
  color: #fff;
  padding-bottom: 8px;
  font-family: ClimateCrisis;
}
@media screen and (max-width: 1023px) {
  .footer__title {
    text-align: center;
  }
}
.footer__text {
  max-width: 580px;
}
@media screen and (max-width: 1023px) {
  .footer__text {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 25px;
  }
}
.footer__text p {
  font-size: 48px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
}
@media screen and (max-width: 1023px) {
  .footer__text p {
    font-size: 38px;
  }
}
@media screen and (max-width: 767px) {
  .footer__text p {
    font-size: 28px;
  }
}
.footer__list {
  display: flex;
  justify-content: flex-end;
  gap: 44px;
  padding-bottom: 34px;
}
@media screen and (max-width: 1199px) {
  .footer__list {
    gap: 16px 28px;
  }
}
@media screen and (max-width: 1023px) {
  .footer__list {
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .footer__list {
    flex-wrap: wrap;
  }
}
.footer__link {
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  transition: 0.25s color;
}
.footer__link:hover {
  color: #3beeb7;
}
.footer__socials {
  display: flex;
  justify-content: flex-end;
  gap: 36px;
  align-items: center;
  padding-bottom: 34px;
}
@media screen and (max-width: 1023px) {
  .footer__socials {
    justify-content: center;
  }
}
.footer__box {
  cursor: pointer;
}
.footer__desc {
  font-size: 20px;
  color: #fff;
  text-align: right;
}
@media screen and (max-width: 1023px) {
  .footer__desc {
    text-align: center;
  }
}
.footer__elem {
  font-size: 140px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding-top: 100px;
  padding-bottom: 16px;
  font-weight: 900;
}
@media screen and (max-width: 1350px) {
  .footer__elem {
    font-size: 120px;
  }
}
@media screen and (max-width: 1150px) {
  .footer__elem {
    font-size: 105px;
  }
}
@media screen and (max-width: 1023px) {
  .footer__elem {
    font-size: 100px;
    padding-top: 60px;
  }
}
@media screen and (max-width: 960px) {
  .footer__elem {
    font-size: 90px;
  }
}
@media screen and (max-width: 840px) {
  .footer__elem {
    font-size: 80px;
  }
}
@media screen and (max-width: 767px) {
  .footer__elem {
    font-size: 60px;
  }
}
@media screen and (max-width: 650px) {
  .footer__elem {
    font-size: 48px;
  }
}
@media screen and (max-width: 480px) {
  .footer__elem {
    font-size: 36px;
  }
}
@media screen and (max-width: 359px) {
  .footer__elem {
    font-size: 30px;
  }
}
.footer__row {
  display: flex;
  gap: 100px;
  justify-content: space-between;
  padding: 0 20px;
}
@media screen and (max-width: 560px) {
  .footer__row {
    gap: 30px;
    padding: 0;
  }
}
.footer__info {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
@media screen and (max-width: 359px) {
  .footer__info {
    gap: 15px;
  }
}
.footer__info:first-child {
  width: 21%;
}
@media screen and (max-width: 560px) {
  .footer__info:first-child {
    width: auto;
  }
}
.footer__info:last-child {
  width: 60%;
}
@media screen and (max-width: 560px) {
  .footer__info:last-child {
    width: auto;
  }
}
.footer__info span {
  font-size: 48px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
}
@media screen and (max-width: 1023px) {
  .footer__info span {
    font-size: 40px;
  }
}
@media screen and (max-width: 767px) {
  .footer__info span {
    font-size: 32px;
  }
}
@media screen and (max-width: 560px) {
  .footer__info span {
    font-size: 24px;
  }
}
@media screen and (max-width: 400px) {
  .footer__info span {
    font-size: 18px;
  }
}
@media screen and (max-width: 359px) {
  .footer__info span {
    font-size: 16px;
  }
}
.footer__site {
  font-size: 16px;
  color: #010101;
  background-color: #3AEEBD;
  padding: 13px 10px;
  text-align: center;
}
.footer__site a {
  color: inherit;
  transition: 0.25s color;
}
.footer__btn {
  cursor: pointer;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.25s background-color;
}
@media screen and (max-width: 767px) {
  .footer__btn {
    width: 60px;
    height: 60px;
  }
}
.footer__btn svg {
  display: block;
  width: 100%;
  max-width: 100%;
  fill: #3AEEBD;
  transition: 0.25s fill;
}
.footer__btn:hover {
  background-color: #35d4a4;
}
.footer__btn:hover svg {
  fill: #fff;
}
.gallery {
  background-color: #010101;
  padding-bottom: 106px;
}
@media screen and (max-width: 1023px) {
  .gallery {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 767px) {
  .gallery {
    padding-bottom: 60px;
  }
}
.gallery__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
@media screen and (max-width: 1023px) {
  .gallery__wrap {
    gap: 30px;
  }
}
@media screen and (max-width: 767px) {
  .gallery__wrap {
    gap: 20px;
  }
}
@media screen and (max-width: 480px) {
  .gallery__wrap {
    gap: 15px;
  }
}
@media screen and (max-width: 359px) {
  .gallery__wrap {
    gap: 10px;
  }
}
.gallery__item img {
  display: block;
  max-width: 100%;
}
.gallery__btn {
  position: relative;
  display: block;
  cursor: pointer;
  max-width: 390px;
  width: 100%;
  font-size: 32px;
  line-height: 1.2;
  color: #3BEEB7;
  background-color: transparent;
  text-transform: uppercase;
  border-radius: 40px;
  padding: 20px 5px;
  margin: 0 auto;
  margin-top: 33px;
  border: 1px solid #3BEEB7;
  font-family: Rubik, sans-serif;
  transition: 0.25s color, 0.25s border-color, 0.25s background-color;
}
@media screen and (max-width: 767px) {
  .gallery__btn {
    font-size: 24px;
    padding: 15px 5px;
  }
}
.gallery__btn:hover {
  background-color: #3beeb7;
  border-color: #3beeb7;
  color: #000;
}
.gallery__btn:after {
  position: absolute;
  cursor: default;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  content: "The portfolio will be available soon!";
  color: #3BEEB7;
  background-color: transparent;
  font-size: 16px;
  width: 100%;
  height: 100%;
  padding: 15px 10px;
  text-transform: none;
}
.gallery__btn.active:after {
  opacity: 1;
  visibility: visible;
}
.header {
  position: relative;
  z-index: 12;
  padding: 40px 0;
}
@media screen and (max-width: 840px) {
  .header {
    padding: 20px 0;
  }
}
.header__title {
  font-size: 32px;
  color: #3BEEB7;
  text-transform: uppercase;
  padding-bottom: 4px;
  font-family: ClimateCrisis;
}
@media screen and (max-width: 767px) {
  .header__title {
    font-size: 28px;
  }
}
.header__desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  text-transform: uppercase;
}
.header__col {
  display: flex;
  gap: 10px;
}
.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__list {
  display: flex;
  align-items: center;
  gap: 0 70px;
}
@media screen and (max-width: 1150px) {
  .header__list {
    gap: 0 40px;
  }
}
@media screen and (max-width: 960px) {
  .header__list {
    gap: 0 30px;
  }
}
@media screen and (max-width: 840px) {
  .header__list {
    display: none;
    width: 100%;
    top: 100%;
    left: 0;
    right: 0px;
    z-index: 3;
    position: absolute;
    max-height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    padding-top: 0px;
    background-color: #fff;
    border-bottom: 1px solid #3beeb7;
  }
}
.header__list li:not(:last-child) {
  position: relative;
}
@media screen and (max-width: 840px) {
  .header__list li:not(:last-child) {
    margin-bottom: 16px;
  }
}
.header__list li:last-child {
  max-width: 198px;
  min-width: 198px;
  width: 100%;
}
@media screen and (max-width: 960px) {
  .header__list li:last-child {
    max-width: 178px;
    min-width: 178px;
  }
}
.header__list li:last-child button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  font-size: 20px;
  color: #3BEEB7;
  padding: 16px 10px;
  padding-left: 40px;
  background: transparent;
  border: 1px solid #3BEEB7;
  border-radius: 25px;
  border: 1px solid #3BEEB7;
  text-transform: uppercase;
  font-family: Rubik, sans-serif;
  transition: 0.25s color, 0.25s background-color;
}
@media screen and (max-width: 960px) {
  .header__list li:last-child button {
    font-size: 18px;
    padding: 10px;
    padding-left: 20px;
  }
}
.header__list li:last-child button svg {
  fill: #3BEEB7;
  transition: 0.25s fill;
}
.header__list li:last-child button:hover {
  background-color: #3BEEB7;
  color: #fff;
}
.header__list li:last-child button:hover svg {
  fill: #fff;
}
@media screen and (max-width: 960px) {
  .header__list li:last-child button {
    padding-left: 30px;
  }
}
.header__link {
  display: block;
  font-size: 20px;
  color: #000000;
  text-transform: uppercase;
  transition: 0.25s color;
}
@media screen and (max-width: 960px) {
  .header__link {
    font-size: 18px;
  }
}
.header__link:hover {
  color: #3beeb7;
}

.mobile-wrap {
  display: none;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
@media screen and (max-width: 840px) {
  .mobile-wrap {
    display: flex;
    padding: 7px 0px;
    padding-bottom: 8px;
  }
}

.line-burger {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 25px;
  height: 3px;
  background-color: #000000;
  transition: 0.5s width;
}
.line-burger:after, .line-burger:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 3px;
  background-color: #000000;
  left: 0;
  transition: 0.5s;
}
.line-burger:after {
  top: calc(100% + 5px);
}
.line-burger:before {
  bottom: calc(100% + 5px);
}

.line-active {
  width: 0;
}
.line-active:after {
  top: 9px;
  transform: rotate(-45deg);
  transform-origin: 0 0;
}
.line-active:before {
  bottom: 9px;
  transform: rotate(45deg);
  transform-origin: 0 100%;
}

.main-nav__toggle {
  position: relative;
  cursor: pointer;
  display: none;
  width: 25px;
  height: 25px;
  margin-top: -5px;
}
@media screen and (max-width: 840px) {
  .main-nav__toggle {
    display: block;
  }
}
.info {
  position: relative;
  padding-top: 140px;
  padding-bottom: 210px;
}
@media screen and (max-width: 1023px) {
  .info {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 767px) {
  .info {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.info .btn {
  position: absolute;
  top: -5px;
  right: 58px;
}
@media screen and (max-width: 1350px) {
  .info .btn {
    right: 8px;
  }
}
@media screen and (max-width: 1249px) {
  .info .btn {
    top: -10px;
  }
}
@media screen and (max-width: 1150px) {
  .info .btn {
    right: 0px;
  }
}
@media screen and (max-width: 1023px) {
  .info .btn {
    top: -16px;
  }
}
@media screen and (max-width: 960px) {
  .info .btn {
    position: static;
    margin: 0 auto;
    margin-top: 40px;
  }
}
.info__scroll {
  position: absolute;
  top: 448px;
  left: -30px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1023px) {
  .info__scroll {
    display: none;
  }
}
.info__scroll svg {
  transform: rotate(90deg) translate(28px, -2px);
  transform-origin: top left;
}
.info__scroll span {
  font-size: 24px;
  color: #3BEEB7;
  text-transform: uppercase;
  transform: rotate(-90deg);
}
.info__wrap {
  position: relative;
}
.info__title {
  font-size: 72px;
  color: #4F4F4F;
  text-transform: uppercase;
  font-weight: 900;
}
@media screen and (max-width: 1350px) {
  .info__title {
    font-size: 68px;
  }
}
@media screen and (max-width: 1249px) {
  .info__title {
    font-size: 60px;
  }
}
@media screen and (max-width: 1150px) {
  .info__title {
    font-size: 54px;
  }
}
@media (max-width: 1080px) {
  .info__title {
    font-size: 47px;
  }
}
@media screen and (max-width: 960px) {
  .info__title {
    text-align: center;
  }
}
@media screen and (max-width: 560px) {
  .info__title {
    font-size: 34px;
  }
}
.info__title span {
  display: block;
  text-align: right;
  padding-top: 40px;
  font-weight: normal;
}
@media screen and (max-width: 960px) {
  .info__title span {
    text-align: center;
  }
}
.info__text {
  max-width: 795px;
  font-size: 24px;
  line-height: 1.75;
  padding-top: 40px;
  padding-bottom: 170px;
  margin-left: auto;
}
@media screen and (max-width: 960px) {
  .info__text {
    padding-bottom: 80px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .info__text {
    font-size: 20px;
    padding-bottom: 60px;
  }
}
.info__city {
  font-size: 72px;
  color: #3BEEB7;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}
@media screen and (max-width: 560px) {
  .info__city {
    font-size: 40px;
  }
}
.partners {
  padding: 146px 0;
}
@media screen and (max-width: 1023px) {
  .partners {
    padding: 80px 0;
  }
}
@media screen and (max-width: 767px) {
  .partners {
    padding: 60px 0;
  }
}
.partners__wrap {
  display: flex;
  justify-content: space-between;
  gap: 25px 20px;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  .partners__wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.partners__item img {
  display: block;
  max-width: 100%;
}
.popup {
  position: fixed;
  z-index: 15;
  top: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  transform: translateX(-100%);
  background-color: #010101;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: transform 0.25s ease-in-out;
}
@media screen and (max-width: 1023px) {
  .popup {
    padding-top: 40px;
  }
}
.popup.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
.popup .title {
  text-align: center;
}
.popup__form {
  max-width: 820px;
  width: 100%;
  max-height: calc(100vh - 80px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  margin: 0 auto;
}
@media screen and (max-width: 1023px) {
  .popup__form {
    position: static;
    overflow: hidden;
    max-height: initial;
    transform: none;
  }
}
@media screen and (max-width: 1023px) {
  .popup__user {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  }
}
.popup__wrap {
  margin-top: 80px;
}
@media screen and (max-width: 1023px) {
  .popup__wrap {
    margin-top: 40px;
  }
}
.popup__item {
  margin-bottom: 50px;
}
@media screen and (max-width: 1023px) {
  .popup__item {
    margin-bottom: 30px;
  }
}
.popup__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 212px;
  width: 100%;
  font-size: 32px;
  color: #3BEEB7;
  text-transform: uppercase;
  background-color: transparent;
  text-align: left;
  border: 1px solid #3BEEB7;
  border-radius: 40px;
  padding: 20px 40px;
  padding-right: 35px;
  margin-left: auto;
  transition: 0.25s color, 0.25s border-color;
}
@media screen and (max-width: 767px) {
  .popup__btn {
    font-size: 24px;
    padding: 14px 40px;
    padding-right: 35px;
    border-radius: 20px;
  }
}
.popup__btn svg {
  fill: #3BEEB7;
  transition: 0.25s fill;
}
.popup__btn:hover {
  border-color: #fff;
  color: #fff;
}
.popup__btn:hover svg {
  fill: #fff;
}
.popup__close {
  position: fixed;
  cursor: pointer;
  top: 100px;
  right: 100px;
  display: block;
  width: 90px;
  height: 90px;
  background-color: transparent;
  border: none;
  padding: 0;
}
@media screen and (max-width: 1199px) {
  .popup__close {
    top: 20px;
    right: 10px;
  }
}
@media screen and (max-width: 1023px) {
  .popup__close {
    position: static;
    margin: 20px;
    margin-left: auto;
    width: 70px;
    height: 70px;
  }
}
.popup__close svg {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.popup__close path {
  transition: 0.25s fill;
}
.popup__close path:first-child {
  fill: #3AEEBD;
}
.popup__close path:last-child {
  fill: #fff;
}
.popup__close:hover path:first-child {
  fill: #fff;
}
.popup__close:hover path:last-child {
  fill: #3AEEBD;
}
.popup__success {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 680px;
  width: 100%;
  transform: translate(-50%, -50%);
  color: #3BEEB7;
  font-size: 68px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .popup__success {
    font-size: 50px;
  }
}
@media screen and (max-width: 560px) {
  .popup__success {
    max-width: 330px;
    font-size: 29px;
  }
}

.modal__form--hidden {
  display: none;
}
.products-wrap {
  padding-top: 170px;
  padding-bottom: 280px;
}
@media screen and (max-width: 1023px) {
  .products-wrap {
    padding-bottom: 100px;
    padding-bottom: 140px;
  }
}
@media screen and (max-width: 767px) {
  .products-wrap {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.products__title {
  font-size: 32px;
  color: #000000;
  text-align: center;
  padding-bottom: 20px;
  font-weight: 900;
}
@media screen and (max-width: 767px) {
  .products__title {
    font-size: 24px;
  }
}
.products__text {
  max-width: 378px;
  margin: 0 auto;
  text-align: center;
}
.products__text p {
  font-size: 20px;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .products__text p {
    font-size: 18px;
  }
}
.products .swiper-slide {
  position: relative;
  height: auto;
  background-color: #F4F9FD;
  border-radius: 40px;
  padding: 0 10px;
  padding-top: 430px;
  padding-bottom: 74px;
}
@media screen and (max-width: 1023px) {
  .products .swiper-slide {
    padding-top: 380px;
  }
}
@media screen and (max-width: 767px) {
  .products .swiper-slide {
    border-radius: 20px;
    padding-bottom: 54px;
  }
}
.products .swiper-slide:nth-child(5) .products__image {
  top: -6px;
}
.products .swiper-slide:nth-child(6) .products__image {
  top: 94px;
}
.products .swiper-slide:nth-child(7) .products__image {
  top: -14px;
}
.products .swiper-slide:nth-child(8) .products__image {
  top: 24px;
}
.products__btn {
  cursor: pointer;
  position: absolute;
  top: calc(100% - 45px);
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 90px;
  height: 90px;
  border: none;
  background: transparent;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .products__btn {
    top: calc(100% - 35px);
    width: 70px;
    height: 70px;
  }
}
.products__btn svg {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  fill: #3BEEB7;
  transition: 0.25s fill;
}
.products__btn:hover svg {
  fill: #35d4a4;
}
.products__image {
  max-width: 400px;
  width: 100%;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 1023px) {
  .products__image {
    max-width: 310px;
  }
}
.products__image img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.products__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 480px) {
  .products__block {
    display: block;
  }
}
.products__holder {
  display: flex;
  gap: 18px;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .products__holder {
    margin-top: 30px;
  }
}
body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

.reviews {
  padding-bottom: 120px;
}
@media screen and (max-width: 1023px) {
  .reviews {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767px) {
  .reviews {
    padding-bottom: 60px;
  }
}
.reviews__inner {
  max-width: 360px;
  display: flex;
  align-items: center;
}
.reviews__image {
  width: 90px;
}
.reviews__image img {
  display: block;
  max-width: 100%;
}
.reviews__info {
  width: calc(100% - 90px);
  padding-left: 28px;
}
.reviews__title {
  font-size: 24px;
  line-height: 1.2;
  color: #000000;
  padding-bottom: 7px;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .reviews__title {
    font-size: 18px;
  }
}
.reviews__position {
  font-size: 16px;
  color: #3AEEBD;
}
.reviews__text {
  position: relative;
  width: calc(100% - 360px);
  margin-left: auto;
  top: -24px;
  padding-top: 30px;
}
@media screen and (max-width: 960px) {
  .reviews__text {
    width: 100%;
    position: static;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.reviews__text:after {
  position: absolute;
  content: "";
  z-index: -1;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 172px;
  height: 120px;
  background-image: url(../../src/img/decor-reviews.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 172px 120px;
}
@media screen and (max-width: 960px) {
  .reviews__text:after {
    display: none;
  }
}
.reviews__text p {
  font-size: 32px;
  font-weight: 300;
}
@media screen and (max-width: 960px) {
  .reviews__text p {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .reviews__text p {
    font-size: 20px;
  }
}
.reviews__holder {
  display: flex;
  align-items: center;
  gap: 18px;
}
.services {
  padding-top: 110px;
  padding-bottom: 220px;
}
@media screen and (max-width: 1023px) {
  .services {
    padding-top: 80px;
    padding-bottom: 120px;
  }
}
@media screen and (max-width: 767px) {
  .services {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.services__wrap {
  margin-top: 86px;
}
@media screen and (max-width: 1023px) {
  .services__wrap {
    margin-top: 40px;
  }
}
.services__item {
  padding: 0 100px;
}
@media screen and (max-width: 1350px) {
  .services__item {
    padding: 0 50px;
  }
}
@media screen and (max-width: 1023px) {
  .services__item {
    padding: 0 20px;
  }
}
.services__item.active {
  background-color: #000000;
}
.services__item.active .services__title,
.services__item.active .services__count {
  color: #fff;
}
@media screen and (max-width: 1023px) {
  .services__item.active .services__text p {
    color: #fff;
  }
}
.services__item.active .services__btn {
  background-color: #3AEEBD;
}
.services__item.active .services__btn svg {
  fill: #fff;
  transform: rotate(-45deg);
}
.services__box {
  display: flex;
  justify-content: space-between;
  padding: 50px 0px;
  border-bottom: 1px solid #D9D9D9;
}
@media screen and (max-width: 1023px) {
  .services__box {
    flex-direction: column;
    padding: 35px 0;
    position: relative;
  }
}
.services__title {
  flex: 1;
  font-size: 24px;
  color: #000;
  text-transform: uppercase;
}
@media screen and (max-width: 1023px) {
  .services__title {
    padding-right: 80px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  .services__title {
    font-size: 20px;
  }
}
@media screen and (max-width: 359px) {
  .services__title {
    font-size: 18px;
  }
}
.services__count {
  flex: 1;
  font-size: 48px;
  color: #000;
  text-align: center;
  font-weight: 300;
}
@media screen and (max-width: 1023px) {
  .services__count {
    order: -1;
    text-align: left;
    padding-right: 80px;
    padding-bottom: 10px;
  }
}
.services__text {
  flex: 1;
}
.services__text p {
  max-height: 0;
  overflow: hidden;
  max-width: 540px;
  font-size: 20px;
  color: #fff;
  margin-left: auto;
  padding-right: 100px;
  transition: 1s;
}
@media screen and (max-width: 1350px) {
  .services__text p {
    padding-right: 50px;
  }
}
@media screen and (max-width: 1023px) {
  .services__text p {
    padding-right: 0;
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .services__text p {
    font-size: 16px;
  }
}
.services__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  transition: 0.5s background-color;
}
@media screen and (max-width: 1023px) {
  .services__btn {
    position: absolute;
    top: 32px;
    right: 0;
  }
}
.services__btn svg {
  fill: #3AEEBD;
  transition: 0.25s transform, 0.25s fill;
}
body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

* {
  box-sizing: border-box;
}

*:after, *:before {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  overflow-x: hidden;
  background-color: #fff;
  color: #131313;
  margin: 0;
  padding: 0px;
  font-family: Rubik, sans-serif;
}
body.hidden {
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 40px;
}
@media screen and (max-width: 1023px) {
  .container {
    padding: 0 20px;
  }
}
.container--inner {
  max-width: 1470px;
}
.container--block {
  max-width: 1360px;
}
.container--video {
  max-width: 1900px;
}
.container--products {
  max-width: 1680px;
}
.container--header {
  max-width: 1680px;
}

.title {
  font-size: 72px;
  color: #4F4F4F;
  text-transform: uppercase;
  font-weight: 900;
}
@media screen and (max-width: 1023px) {
  .title {
    font-size: 54px;
  }
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 34px;
  }
}
.title--white {
  color: #fff;
}

.btn {
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 32px;
  font-size: 32px;
  color: #3BEEB7;
  background-color: transparent;
  padding: 0;
  border: none;
  text-transform: uppercase;
  font-family: Rubik, sans-serif;
  transition: 0.25s color;
}
@media screen and (max-width: 767px) {
  .btn {
    font-size: 24px;
    gap: 20px;
  }
}
@media screen and (max-width: 359px) {
  .btn {
    font-size: 22px;
  }
}
.btn svg {
  display: block;
  max-width: 90px;
  fill: #3BEEB7;
  transition: 0.25s fill;
}
@media screen and (max-width: 767px) {
  .btn svg {
    max-width: 70px;
  }
}
.btn:hover {
  color: #35d4a4;
}
.btn:hover svg {
  fill: #35d4a4;
}

.input {
  display: block;
  width: 100%;
  font-size: 32px;
  color: #3BEEB7;
  background: transparent;
  border: none;
  border-radius: 40px;
  border-bottom: 2px solid #ddd;
  text-align: center;
  padding: 20px 20px;
  font-family: Rubik;
}
@media screen and (max-width: 1023px) {
  .input {
    font-size: 27px;
    border-radius: 20px;
    padding: 15px 20px;
  }
}
@media screen and (max-width: 767px) {
  .input {
    font-size: 24px;
    padding: 14px 20px;
    border-radius: 20px;
  }
}
.input.area {
  min-height: 140px;
  resize: none;
}

.swiper__prev img, .swiper__next img {
  display: block;
  max-width: 100%;
}
.swiper__prev:not(.swiper-button-disabled), .swiper__next:not(.swiper-button-disabled) {
  transition: 0.25s opacity;
}
.swiper__prev:not(.swiper-button-disabled):hover, .swiper__next:not(.swiper-button-disabled):hover {
  cursor: pointer;
  opacity: 0.6;
}

.error-message {
  display: block;
  font-size: 14px;
  color: tomato;
  padding-top: 20px;
}
.video__wrap {
  position: relative;
  cursor: pointer;
  height: 0;
  padding-top: 38.25%;
}
@media screen and (max-width: 767px) {
  .video__wrap {
    padding-top: 58.25%;
  }
}
@media screen and (max-width: 359px) {
  .video__wrap {
    padding-top: 78.25%;
  }
}
.video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  max-width: 100%;
  object-fit: cover;
  border-radius: 100px 100px 0 0;
}
@media screen and (max-width: 1023px) {
  .video video {
    border-radius: 50px 50px 0 0;
  }
}
.video__btn {
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  font-size: 32px;
  color: #3BEEB7;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  padding: 0;
  padding-bottom: 34px;
  font-family: Rubik, sans-serif;
}
@media screen and (max-width: 1023px) {
  .video__btn {
    padding-bottom: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: initial;
  }
}
.video__btn img {
  max-width: 90px;
}
@media screen and (max-width: 767px) {
  .video__btn img {
    max-width: 70px;
  }
}
@media screen and (max-width: 1023px) {
  .video__btn span {
    display: none;
  }
}
.who {
  background-color: #010101;
  padding-top: 188px;
  padding-bottom: 126px;
}
@media screen and (max-width: 1023px) {
  .who {
    padding-top: 140px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 767px) {
  .who {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.who .title {
  text-align: right;
}
.who__text {
  font-size: 64px;
  color: #fff;
  text-align: right;
  text-transform: uppercase;
  margin-top: 85px;
  margin-bottom: 100px;
  font-weight: 300;
}
@media screen and (max-width: 1023px) {
  .who__text {
    font-size: 44px;
    margin: 60px 0;
  }
}
@media screen and (max-width: 767px) {
  .who__text {
    font-size: 24px;
    margin: 40px 0;
  }
}
.who__inner {
  display: flex;
  gap: 30px;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (max-width: 840px) {
  .who__inner {
    flex-direction: column;
    gap: 20px;
  }
}
@media screen and (max-width: 840px) {
  .who__inner .btn {
    margin-left: auto;
  }
}
.who__btn {
  font-size: 32px;
  line-height: 1.2;
  color: #3BEEB7;
}
.who__info {
  max-width: 720px;
  font-size: 32px;
  line-height: 1.5;
  color: #fff;
  text-align: right;
}
@media screen and (max-width: 1023px) {
  .who__info {
    font-size: 28px;
    margin-left: auto;
  }
}
@media screen and (max-width: 767px) {
  .who__info {
    font-size: 18px;
  }
}