@import 'responsive';

.who {
	background-color: #010101;
	padding-top: 188px;	
	padding-bottom: 126px;	
	@include media(mdx) {
		padding-top: 140px;
		padding-bottom: 100px;
	}
	@include media(sm) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.title {
		text-align: right;
	}
	&__text {
		font-size: 64px;
		color: #fff;	
		text-align: right;
		text-transform: uppercase;
		margin-top: 85px;
		margin-bottom: 100px;
		font-weight: 300;
		@include media(mdx) {
			font-size: 44px;
			margin: 60px 0;
		}
		@include media(sm) {
			font-size: 24px;
			margin: 40px 0;
		}
	}
	&__inner {
		display: flex;
		gap: 30px;
		align-items: flex-start;
		justify-content: space-between;
		@include media(smdx) {
			flex-direction: column;
			gap: 20px;
		}
		.btn {
			@include media(smdx) {
				margin-left: auto;
			}
		}
	}
	&__btn {
		font-size: 32px;
		line-height: 1.2;
		color: #3BEEB7;
	}
	&__info {
		max-width: 720px;
		font-size: 32px;
		line-height: 1.5;
		color: #fff;
		text-align: right;
		@include media(mdx) {
			font-size: 28px;
			margin-left: auto;
		}
		@include media(sm) {
			font-size: 18px;
		}
	}
}