body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}
    
    
table {
    border-collapse: collapse;
    border-spacing: 0;
}
    
a {
    text-decoration: none;
}

fieldset,
img,
abbr {
    border: 0;
}
    
address,
caption,
cite,
code,
dfn,
th,
var {
    font-style: normal;
    font-weight: normal;
}
    

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}
    
ul li {
    list-style: none;
}
    
caption,
th {
    text-align: left;
}
        
    
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}
    
sup {
    vertical-align: text-top;
}
    
sub {
    vertical-align: text-bottom;
}
    
input,
textarea,
select {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    outline: none;
}
    
    
legend {
    color: #000;
}
    
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {

    display: block;
}
