$break_desc: 1700px;
$break_lgb: 1550px;
$break_lgkt: 1505px;
$break_lg: 1430px;
$break_lgm: 1350px;
$break_lgs: 1290px;
$break_lgk: 1249px;
$break_lgsm: 1199px;
$break_lgxs: 1150px;
$break_mdx: 1023px;
$break_md: 960px;
$break_smdx: 840px;
$break_sm: 767px;
$break_smx: 650px;
$break_xs: 560px;
$break_xxs: 480px;
$break_mini: 400px;
$break_small: 359px;

@mixin media($media) {
	@if $media == desc {
		@media screen and (max-width: $break_desc) {
			@content; 
		}
	} @else if $media == lgb {
		@media screen and (max-width: $break_lgb) {
			@content;
		}
	} @else if $media == lgkt {
		@media screen and (max-width: $break_lgkt) {
			@content;
		}
	} @else if $media == lg {
		@media screen and (max-width: $break_lg) {
			@content;
		}
	} @else if $media == lgm {
		@media screen and (max-width: $break_lgm) {
			@content;
		} 
	}
	@else if $media == lgs {
		@media screen and (max-width: $break_lgs) {
			@content;
		}
	} @else if $media == lgk {
		@media screen and (max-width: $break_lgk) {
			@content;
		}
	}  @else if $media == lgsm {
		@media screen and (max-width: $break_lgsm) {
			@content;
		}
	}  @else if $media == lgxs {
		@media screen and (max-width: $break_lgxs) {
			@content;
		}
	} @else if $media == mdx {
		@media screen and (max-width: $break_mdx) {
			@content;
		}
	} @else if $media == md {
		@media screen and (max-width: $break_md) {
			@content;
		}
	} @else if $media == smdx {
		@media screen and (max-width: $break_smdx) {
			@content;
		}
	} @else if $media == sm {
		@media screen and (max-width: $break_sm) {
			@content;
		}
	} @else if $media == smx {
		@media screen and (max-width: $break_smx) {
			@content;
		}
	} @else if $media == xs {
		@media screen and (max-width: $break_xs) {
			@content;
		}
	} @else if $media == xxs {
		@media screen and (max-width: $break_xxs) {
			@content;
		}
	} @else if $media == mini {
		@media screen and (max-width: $break_mini) {
			@content;
		}
	} @else if $media == small {
		@media screen and (max-width: $break_small) {
			@content;
		}
	} @else if $media == reverse-md {
		@media screen and (min-width: $break_md + 1) {
			@content;
		}
	} 
}