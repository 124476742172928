@import 'responsive';

.reviews {
	padding-bottom: 120px;
	@include media(mdx) {
		padding-bottom: 80px;
	}
	@include media(sm) {
		padding-bottom: 60px;
	}
	&__inner {
		max-width: 360px;
		display: flex;
		align-items: center;
	}
	&__image {
		width: 90px;
		img {
			display: block;
			max-width: 100%;
		}
	}
	&__info {
		width: calc(100% - 90px);
		padding-left: 28px;
	}
	&__title {
		font-size: 24px;
		line-height: 1.2;
		color: #000000;
		padding-bottom: 7px;
		font-weight: 700;
		@include media(sm) {
			font-size: 18px;
		}
	}
	&__position {
		font-size: 16px;
		color: #3AEEBD;
	}
	&__text {
		position: relative;
		width: calc(100% - 360px);
		margin-left: auto;
		top: -24px;
		padding-top: 30px;
		@include media(md) {
			width: 100%;
			position: static;
			padding-top: 20px;
			padding-bottom: 20px;
		}
		&:after {
			position: absolute;
			content: '';
			z-index: -1;
			top: -50px;
			left: 50%;
			transform: translateX(-50%);
			width: 172px;
			height: 120px;
			background-image: url(../../src/img/decor-reviews.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 172px 120px;
			@include media(md) {
				display: none;
			}
		}
		p {
			font-size: 32px;
			font-weight: 300;
			@include media(md) {
				font-size: 28px;
			}
			@include media(sm) {
				font-size: 20px;
			}
		}
	}
	&__holder {
		display: flex;
		align-items: center;
		gap: 18px;
	}
}