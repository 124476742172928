@import 'responsive';

.video {
	&__wrap {
		position: relative;
		cursor: pointer;
		height: 0;
		padding-top: 38.25%;
		@include media(sm) {
			padding-top: 58.25%;
		}
		@include media(small) {
			padding-top: 78.25%;
		}
	}
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;	
		height: 100%;	
		display: block;
		max-width: 100%;
		object-fit: cover;
		border-radius: 100px 100px 0 0;
		@include media(mdx) {
			border-radius: 50px 50px 0 0;
		}
	}
	&__btn {
		position: absolute;
		cursor: pointer;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 30px;
		font-size: 32px;
		color: #3BEEB7;
		border: none;
		background-color: transparent;
		text-transform: uppercase;
		padding: 0;
		padding-bottom: 34px;
		font-family: Rubik, sans-serif;
		@include media(mdx) {
			padding-bottom: 0;
			top: 50%;
			transform: translate(-50%, -50%);
			bottom: initial;
		}
		img {
			max-width: 90px;
			@include media(sm) {
				max-width: 70px;
			}
		}
		span {
			@include media(mdx) {
				display: none;
			}
		}
	}
}